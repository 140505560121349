import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';

const SpotifyBlockView = (props) => {
    const { editor, getPos, node } = props;
    console.log({ node: node })
    const embedRef = useRef('');
    useEffect(() => {
        if (embedRef.current) {
            embedRef.current.innerHTML = node.attrs.embedHtml;
        }
    }, [node.attrs.embedHtml]);
    return (
        <NodeViewWrapper className="spotify-node-wrapper" data-type='spotify'>
            <div className="spotifyEmbed" ref={embedRef} />
        </NodeViewWrapper>
    );
};
export const SpotifyBlock = Node.create({
    name: 'spotifyBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            embedHtml: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ node }) {
        return [
            'div',
            { 'data-type': this.name },
            node.attrs.embedHtml || '',  // Render the embedded Spotify's HTML content
        ];
    },

    addCommands() {
        return {
            setSpotifyBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                embedHtml: options?.embedHtml || '',
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(SpotifyBlockView);
    },
});