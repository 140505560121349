import React, { useState, useEffect, useCallback } from "react";
import { BubbleMenu } from "@tiptap/react";
import icons from "../../iconsList.json";
import axios from 'axios';

const PublicationBubbleMenu = ({ editor, appendTo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(3);
  const [column, setColumn] = useState(3);
  const [categories, setCategories] = useState([])
  const [selectedCategorie, setSelectedCategorie] = useState([])
  const [editAttributes, setEditAttributes] = useState({
    title: "Publiations",
    layout: "grid",
    categories: []
  });

  useEffect(() => {
    if (editor && editor.isActive("publiations")) {
      const attrs = editor.getAttributes("publiations");
      setEditAttributes((prev) => ({
        ...prev,
        ...attrs,
        layout: attrs.layout || "grid",
        categories: attrs.categories || []
      }));
    }
  }, [editor]);

  const shouldShow = useCallback(() => {
    if (editor.isActive("publiations")) {
      const defaultcategories = editor.state.selection.node?.attrs?.categories;
      setSelectedCategorie(defaultcategories)
      setButtonAttribute("categories", defaultcategories);
      const defaultcolumn = editor.state.selection.node?.attrs?.column;
      setColumn(defaultcolumn);
      setButtonAttribute("column", defaultcolumn);
      const defaultcount = editor.state.selection.node?.attrs?.count;
      setCount(defaultcount);
      setButtonAttribute("count", defaultcount);
    }
    return editor.isActive("publiations") && editor.isFocused;
  }, [editor]);

  const setButtonAttribute = (key, value) => {
    setEditAttributes((prev) => {
      const newAttrs = { ...prev, [key]: value };
      return newAttrs;
    });
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseDown = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleMouseUp = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleTouchStart = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.add("slider-cursor");
  };

  const handleTouchEnd = () => {
    const slider = document.getElementById("zoom-range");
    slider.classList.remove("slider-cursor");
  };

  const handleCount = (count) => {
    setCount(count);
  };

  const handleColumn = (column) => {
    setColumn(column);
    setButtonAttribute("column", column);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://jourmal-node-dashboard.onrender.com/api/categories/all/65e8aff43b2cc6c72b9961d7`,
      )
      setCategories(response.data.result)
    } catch (error) {
      console.log(error, "error");
    }
  }
  const handleApplyChange = () => {
    if (editor) {
      editor.chain().focus().updateAttributes("publiations", editAttributes).run();
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const allCategories = categories.map(item => item.name).filter(name => name !== undefined);
  const deleteCategories = (category) => {
    const updatedCategories = selectedCategorie.filter((item) => item !== category);
    setSelectedCategorie(updatedCategories);
    setButtonAttribute("categories", updatedCategories);
  };
  return (
    <BubbleMenu
      editor={editor}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        zIndex: 99,
        popperOptions: {
          modifiers: [{ name: "flip", enabled: false }],
        },
        appendTo: () => appendTo?.current,
        onHidden: () => setIsOpen(false)
      }}
    >
      <div className="bubble-toggle-menu button-box">
        <div className="categories-box">
          <label className="menu-title">Categories</label>
          <div className="categories-button" onClick={toggleDropdown}>
            <div className="select-categories">Select Categories</div>
            <div
              className="menu-icon"
              dangerouslySetInnerHTML={{ __html: icons["DownArrow"] }}
            />
          </div>
          {(selectedCategorie && selectedCategorie.length > 0) &&
            <div className="categories">
              <>
                {
                  selectedCategorie?.map((item) => {
                    return (
                      <div className="selected-categorie">
                        <p>{item}</p>
                        <svg width="20" height="20" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => deleteCategories(item)}>
                          <path d="M7 6.72234L9.72234 4L10.5 4.77766L7.77766 7.5L10.5 10.2223L9.72234 11L7 8.27766L4.27766 11L3.5 10.2223L6.22234 7.5L3.5 4.77766L4.27766 4L7 6.72234Z" fill="#868C98" />
                        </svg>
                      </div>
                    )
                  })
                }
              </>
            </div>
          }
        </div>
        <div className="alignment-box">
          <label className="menu-title layout-title">Layout</label>
          <div className="alignment-buttons layout">
            <button
              className={`toolbar-button ${editAttributes.layout === "list" ? "active" : ""}`}
              onClick={() => setButtonAttribute("layout", "list")}
            >
              <div dangerouslySetInnerHTML={{ __html: icons["ListLayout"] }} />
            </button>
            <button className={`toolbar-button ${editAttributes.layout === "grid" ? "active" : ""}`}
              onClick={() => setButtonAttribute("layout", "grid")}
            >
              <div dangerouslySetInnerHTML={{ __html: icons["GridLayout"] }} />
            </button>

          </div>
        </div>
        <div className="publication-count">
          <div className="count-title">
            <label>Publication Count</label>
            <div>{count}</div>
          </div>
          <input
            id="zoom-range" className="zoom-range" type="range"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            value={count} min={1} max={6} step={1}
            style={{ background: `linear-gradient(to right, #0A2540 ${((count - 1) / 5) * 100}%, #B7BCC2 0%)`, height: "5px" }}
            onChange={(e) => {
              const newCount = e.target.value;
              handleCount(newCount);
              setButtonAttribute("count", newCount);
            }}
          />
        </div>
        {
          editAttributes.layout === "grid" &&
          <div className="publication-column">
            <div className="column-title">
              <label>Columns</label>
              <div>{column}</div>
            </div>
            <input
              id="zoom-range" className="zoom-range" type="range"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              value={column} min={1} max={4} step={1}
              style={{ background: `linear-gradient(to right, #0A2540 ${((column - 1) / 3) * 100}%, #B7BCC2 0%)`, height: "5px" }}
              onChange={(e) => { handleColumn(e.target.value); }}
            />
          </div>
        }


        <div className='button-element publication-submit-button'>
          <button onClick={handleApplyChange}>Apply</button>
        </div>

        {isOpen && <div className="content-type-menu categories-menu">
          {allCategories.map((item) => {
            const isSelected = selectedCategorie.includes(item); // Check if the category is already selected
            return (
              <div className="selectedcat" key={item} onClick={() => {
                if (isSelected) {
                  // If the category is selected, remove it from the selectedCategorie array
                  const updatedCategories = selectedCategorie.filter((cat) => cat !== item);
                  setSelectedCategorie(updatedCategories);
                  setButtonAttribute("categories", updatedCategories); // Update the editAttributes with the new categories
                } else {
                  // If the category is not selected, add it to the selectedCategorie array
                  const updatedCategories = [...selectedCategorie, item];
                  setSelectedCategorie(updatedCategories);
                  setButtonAttribute("categories", updatedCategories); // Update the editAttributes with the new categories
                }
              }}>
                <input className="categoriesSelect" type="checkbox" checked={isSelected} />
                <p className="categories">
                  {item}
                </p>
              </div>
            );
          })}

        </div>}

      </div>
    </BubbleMenu>
  );
};

export default PublicationBubbleMenu;
