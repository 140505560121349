import { useEffect, useState } from 'react';

export const TableOfContents = ({ editor, onItemClick }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const handler = ({ editor: currentEditor }) => {
      setData({ ...currentEditor.extensionStorage.tableOfContents });
    };

    handler({ editor });

    editor.on('update', handler);
    editor.on('selectionUpdate', handler);

    return () => {
      editor.off('update', handler);
      editor.off('selectionUpdate', handler);
    };
  }, [editor]);

  return (
    <>
      <div className="TableOfContentsTitle">
        Table of contents
      </div>
      {data && data.content.length > 0 ? (
        <div className="TableOfContentsCover">
          {data.content.map(item => (
            <a
              key={item.id}
              href={`#${item.id}`}
              style={{ marginLeft: `${1 * item.level - 1}rem` }}
              onClick={onItemClick}
              className={`TableOfContentsItem`}              
            >
              {item.itemIndex}. {item.textContent}
            </a>
          ))}
        </div>
      ) : (
        <div className="TableOfContentsNoData">Start adding headlines to your document …</div>
      )}
    </>
  );
};
