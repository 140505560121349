import { NodeViewWrapper } from '@tiptap/react';
import { useEffect, useState } from 'react';
import axios from 'axios';

const BookmarkInsertNodeView = ({ node, editor, getPos }) => {
  const [shareUrl, setShareUrl] = useState('');
  const [isValidURL, setIsValidURL] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMesssage] = useState("");

  const handleShareInputChange = async (e) => {
    const trimmedValue = e.target.value.trim();
    setShareUrl(trimmedValue);
    setIsValidURL(false); 
    
    if (trimmedValue.length > 0) {
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
      const isUrlValid = urlPattern.test(trimmedValue);
      setIsValidURL(isUrlValid);
    }
    setErrorMesssage("")
  };

  const handleSubmit = async () =>{
    if (isValidURL) {
      const getSiteData = async () => {
        try {
          setIsLoading(true);
          const urlData = { url: shareUrl };
          const response = await axios.post(
            `https://jourmal-node-dashboard.onrender.com/api/publicationurl/link`,
            urlData
          );
          
          if (response?.data?.previewData) {        
            // Insert the bookmark data into the editor  
            const currentNodePos =  getPos();     
            console.log({currentNodePos})               
            // editor.chain().setMeta('bookmark', true).setNodeSelection(currentNodePos).deleteSelection().run()        
    
            editor.chain().focus().setBookmarkBlock({
              title: response.data.previewData?.title || '',
              description: response.data.previewData?.description || '',
              imageUrl: response.data.previewData?.imageUrl || '',
              siteTitle: response.data.previewData?.siteTitle || '',
              favicon: response.data.previewData?.faviconSrc || '',
              url: shareUrl,
            }).run();
          }
        } catch (error) {
          console.error('Failed to fetch URL data:', error);
          setErrorMesssage("No data found")
          setIsLoading(false);
        } finally {
          setIsLoading(false);
        }
      }
      await getSiteData()
    } else {
      setErrorMesssage("The URL is not valid")
    }
  }

  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        handleSubmit();
    }
  };
  return (
    <NodeViewWrapper>
      <div data-type='bookmark' className={`bookmark-node-wrapper ${isLoading && 'is-loading'}`} data-drag-handle>
        <label>
          <div className='urlInput'>
            {isLoading ? (
              <div className='linkbutton-loader loader-buttonHeight'>
                <div className='ring-loader'></div>
              </div>
            ) : (
              <>
              
				      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-link w-4 h-4"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>
                <input
                  className='urlInputLeft'
                  type='text'
                  placeholder='Paste URL to add bookmark content...'
                  value={shareUrl}
                  onChange={handleShareInputChange}
                  onKeyPress={(e) => { handleKeyPress(e) }}
                />
                {
                  errorMessage && 
                <p className="error-message" >{errorMessage}</p>
                }
              </>
            )}
          </div>
        </label>
      </div>
    </NodeViewWrapper>
  );
};

export default BookmarkInsertNodeView;
