import { Node, mergeAttributes } from '@tiptap/core';

export const Button = Node.create({
  name: 'button',

  group: 'block',
  atom: true,

  addAttributes() {
    return {
      title: {
        default: 'Button',
      },
      href: {
        default: '',
      },
      align: {
        default: 'center',
      },
      width: {
        default: 'auto',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div[data-type="button"]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    // Set up classes based on attributes
    const alignmentClass = `align-${HTMLAttributes.align}`;
    const widthClass = HTMLAttributes.width === 'full' ? 'width-full' : 'width-auto';

    return [
      'div',
      mergeAttributes({ 'data-type': 'button' }, HTMLAttributes),
      [
        'div',
        { class: `button-element ${alignmentClass} ${widthClass}` }, // Add default class 'button-element'
        [
          'button',
          {
            onclick: () => {
              const { href } = HTMLAttributes;
              if (href) {
                window.open(href, '_blank');
              }
            },
          },
          HTMLAttributes.title,
        ],
      ],
    ];
  },

  addCommands() {
    return {
      setButton:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
      },
      updateButton:
        (options) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, options);
      },
      isActive: () => ({ commands }) => {
        return commands.isActive(this.name);
      },
    };
  },

  addNodeView() {
    return ({ node, getPos, editor }) => {
      const wrapper = document.createElement('div');
      wrapper.setAttribute('data-type', 'button');

      const buttonWrapper = document.createElement('div');
      const alignmentClass = `align-${node.attrs.align}`;
      const widthClass = node.attrs.width === 'full' ? 'width-full' : 'width-auto';
      // Add default class 'button-element'
      buttonWrapper.className = `button-element ${alignmentClass} ${widthClass}`;

      const button = document.createElement('button');
      button.innerHTML = node.attrs.title;
      button.onclick = () => {
        const { href } = node.attrs;
        if (href) {
          window.open(href, '_blank');
        }
      };

      buttonWrapper.appendChild(button);
      wrapper.appendChild(buttonWrapper);

      return {
        dom: wrapper,
        contentDOM: buttonWrapper,
        update: (updatedNode) => {
          if (updatedNode.attrs !== node.attrs) {
            button.innerHTML = updatedNode.attrs.title;
            // Update classes based on new attributes
            const newAlignmentClass = `align-${updatedNode.attrs.align}`;
            const newWidthClass = updatedNode.attrs.width === 'full' ? 'width-full' : 'width-auto';

            // Ensure 'button-element' class remains while updating other classes
            buttonWrapper.className = `button-element ${newAlignmentClass} ${newWidthClass}`;
          }
          return true;
        },
      };
    };
  },
});
