import { NodeViewWrapper } from "@tiptap/react";
import { marked } from "marked";
import { useState } from "react";
const { GoogleGenerativeAI } = require("@google/generative-ai");


export const AiWriterView = (props) => {
    const { editor, getPos, node, deleteNode } = props;

    const genAI = new GoogleGenerativeAI('AIzaSyCzTHvmEXPmB6vqnK4iwSKSd0A70aVlUuY');
    const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" })

    const [selectedTone, setSelectedTone] = useState('')
    const [promptText, setPromptText] = useState('');
    const [generatedText, setGeneratedText] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const options = {
        reset: '',
        academic: 'Academic',
        business: 'Business',
        casual: 'Casual',
        conversational: 'Conversational',
        emotional: 'Emotional',
        humorous: 'Humorous',
        informative: 'Informative',
        inspirational: 'Inspirational',
        memeify: 'Memeify',
        narrative: 'Narrative',
        objective: 'Objective',
        persuasive: 'Persuasive',
        poetic: 'Poetic'
    };

    const generateText = async () => {

        if (!promptText.trim()) {
            setErrorMessage("Please enter a prompt to generate text.");
            return;
        }

        try {
            setIsGenerating(true);

            setGeneratedText('');
            setErrorMessage('');

            const toneInstruction = selectedTone ? `Write in a ${selectedTone} tone: ` : '';
            const finalPrompt = toneInstruction + promptText;

            const result = await model.generateContentStream(finalPrompt);
            let fullText = '';

            for await (const chunk of result.stream) {
                const chunkText = chunk.text();

                // Append new chunk to the full text
                fullText += chunkText;

                // Set the text progressively for "typing" effect
                setGeneratedText(fullText);

                // Optionally, add a slight delay to make the typing effect more noticeable
                await new Promise(resolve => setTimeout(resolve, 100)); // 100ms delay for effect
            }

            setIsGenerating(false);  // Stop loading state
            console.log({ generatedText: fullText });
        } catch (error) {
            setIsGenerating(false);  // Stop loading state even on error
            console.error("Error generating text:", error);
        }
    };

    const regenerateText = async () => {
        // Ensure that there is a previous generation to regenerate
        if (!promptText.trim()) {
            setErrorMessage("No previous prompt found to regenerate text.");
            return;
        }

        // Optionally, you can implement additional logic here if needed
        generateText();
    };

    const getSanitizedHTML = (text) => {
        const rawHTML = marked(text);  // Convert Markdown to raw HTML
        return rawHTML; // Sanitize the raw HTML
    };

    const insert = () => {
        const from = getPos();
        const to = from + node.nodeSize;
      
        editor.chain().focus().insertContentAt({ from, to }, getSanitizedHTML(generatedText)).run();
    };
    
    const discard = () => {
        deleteNode();
    };

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const handleOptionClick = (value) => {
        setSelectedTone(value);
        setDropdownOpen(false);
    };

    return (
        <NodeViewWrapper data-drag-handle data-type='AiWriter'>
            <div className="AiWriter-wrapper">
                { generatedText &&
                    <>
                        <div className="preview-title">Preview</div>
                        <div 
                            className="aitext-preview" 
                            dangerouslySetInnerHTML={{ __html: getSanitizedHTML(generatedText) }} 
                        />
                    </>
                }

                <div className="aitext-generator">
                    <label> Prompt </label>
                    
                    <textarea className="prompt-textarea" onChange={(e) => setPromptText(e.target.value)} placeholder="Tell me what you want me to write about."style={{width:"100%"}}> </textarea>
                    <div className="generator-menu">
                        <div className="generator-menulist" onClick={toggleDropdown}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-mic w-4 h-4">
                                        <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"></path>
                                        <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                                        <line x1="12" x2="12" y1="19" y2="22"></line>
                                    </svg>
                                        {selectedTone || 'Change tone'}
                                        <svg className="downarrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><path d="m6 9 6 6 6-6"></path></svg>
                                        {dropdownOpen && (
                                            <ul className="dropdown-menu menuitem">
                                                <li className="categories" onClick={() => handleOptionClick(options.reset)}>
                                                    {selectedTone ? 'Reset' : 'Change tone'}
                                                </li>
                                                {Object.values(options).slice(1).map((option, index) => (
                                                    <li className="categories" key={index} onClick={() => handleOptionClick(option)}>
                                                        {option}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                </div>

                        { generatedText ?
                            <div className="button">
                                <div className="generate-button">
                                    <button className={`toolbar-button button-discard`} onClick={discard} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" ><path d="M3 6h18"></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path></svg>    
                                        Discard
                                    </button>
                                </div>
                                <div className="generate-button">
                                    <button className={`toolbar-button button-insert`} onClick={insert} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" ><path d="M20 6 9 17l-5-5"></path></svg>
                                        Insert
                                    </button>
                                </div>
                                <div className="generate-button">
                                    <button className={`toolbar-button button-regenerate`} onClick={regenerateText} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" ><path d="m17 2 4 4-4 4"></path><path d="M3 11v-1a4 4 0 0 1 4-4h14"></path><path d="m7 22-4-4 4-4"></path><path d="M21 13v1a4 4 0 0 1-4 4H3"></path></svg> 
                                        Regenerate
                                    </button>
                                </div>
                            </div>
                            :
                            <div className="generate-button">
                                <button className={`toolbar-button`} onClick={generateText} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"><path d="M9.937 15.5A2 2 0 0 0 8.5 14.063l-6.135-1.582a.5.5 0 0 1 0-.962L8.5 9.936A2 2 0 0 0 9.937 8.5l1.582-6.135a.5.5 0 0 1 .963 0L14.063 8.5A2 2 0 0 0 15.5 9.937l6.135 1.581a.5.5 0 0 1 0 .964L15.5 14.063a2 2 0 0 0-1.437 1.437l-1.582 6.135a.5.5 0 0 1-.963 0z"></path><path d="M20 3v4"></path><path d="M22 5h-4"></path><path d="M4 17v2"></path><path d="M5 18H3"></path></svg>
                                    Generate Text
                                </button>
                            </div>
                        }
                    </div>
                    
                </div>
            </div>
        </NodeViewWrapper>
    )
}