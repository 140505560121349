import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import BookmarkBlockView from './BookmarkBlockView';

export const BookmarkBlock = Node.create({
    name: 'bookmarkBlock',
  
    group: 'block',

    defining: true,

    isolating: true,
  
    addAttributes() {
      return {
        url: {
          default: '',
        },
        title: {
          default: '',
        },
        description: {
          default: '',
        },
        imageUrl: {
          default: '',
        },
        siteTitle: {
          default: '',
        },
        favicon: {
          default: '',
        },
        url: {
          default: '',
        },
      };
    },
  
    parseHTML() {
      return [
        {
          tag: `div[data-type="${this.name}"]`,
        },
      ];
    },
  
    renderHTML({ HTMLAttributes }) {
      return [
        'div',
        { 'data-type': this.name },
        [
          'div',
          { class: 'bookmark-content' },
          [
            'h3',
            { class: 'bookmark-title' },
            HTMLAttributes.title || 'No Title',
          ],
          [
            'p',
            { class: 'bookmark-description' },
            HTMLAttributes.description || 'No Description',
          ],
          HTMLAttributes.imageUrl
            ? ['img', { src: HTMLAttributes.imageUrl, class: 'bookmark-image' }]
            : '',
        ],
      ];
    },
  
    addCommands() {
      return {
        setBookmarkBlock:
          (options) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: {
                title: options?.title || 'No Title',
                description: options?.description || 'No Description',
                imageUrl: options?.imageUrl || '',
                siteTitle: options?.siteTitle || '',
                favicon: options?.favicon || '',
                url: options?.url || '',
              },
            });
          },
      };
    },
  
    addNodeView() {
      return ReactNodeViewRenderer(BookmarkBlockView);
    },
  });
  