import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState, useEffect, useRef } from 'react';

const TwitterNodeView = ({ node, editor, getPos }) => {
  const [twitterUrl, setTwitterUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setTwitterUrl(trimmedValue);
    setErrorMessage("");
  };

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      if (twitterUrl !== '') {
        const twitterPattern = /^(https?:\/\/)?(www\.)?twitter\.com\/.+\/status\/.+$/;
        const twitterXPattern = /^(https?:\/\/)?(www\.)?x\.com\/.+\/status\/.+$/;

        if (twitterPattern.test(twitterUrl) || twitterXPattern.test(twitterUrl)) {
          editor.chain().focus().setTwitterBlock({
            twitterUrl: twitterUrl,
          }).run();
          setErrorMessage("");
        } else {
          setErrorMessage("Please enter a valid URL");
        }
      }
    }
  };

  // Load the Twitter widget script when the component mounts
  useEffect(() => {
    // Load Twitter's widgets.js script
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <NodeViewWrapper>
      <div data-type="twitter" className="twitter-node-wrapper" data-drag-handle>
        <label>
          <div className='urlInput'>
            <input
              className="urlInputLeft"
              type="text"
              placeholder="Paste X (formerly Twitter) URL to add embedded content"
              value={twitterUrl}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            {
              errorMessage &&
              <p className="error-message">{errorMessage}</p>
            }
          </div>
        </label>
      </div>
    </NodeViewWrapper>
  );
};

export const TwitterEmbeds = Node.create({
    name: 'twitter',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    addAttributes() {
        return {
            twitterUrl: {
                default: '',
            },
            embedHtml: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ node }) {
        return [
            'div',
            { 'data-type': this.name },
            node.attrs.embedHtml || '',  // Render the embedded tweet's HTML content
        ];
    },

    addCommands() {
        return {
            setTwitterEmbeds: () => ({ commands }) =>
                commands.insertContent(`<div data-type="${this.name}"></div>`),
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(TwitterNodeView);
    },
});

