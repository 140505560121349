import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState, useRef } from 'react';

const ImageNodeView = ({ node, editor, getPos }) => {
    const [isImageDragging, setIsImageDragging] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const pulicationFileInputRef = useRef(null);

    // Handle Dragging Events
    const handleFileDragOver = (event) => {
        event.preventDefault();
        setIsImageDragging(true);
    };

    const handleFileDragLeave = () => {
        setIsImageDragging(false);
    };

    // Handle File Upload
    const handleImageGalleryUpload = (event) => {
        const files = event.target.files;
        handleFilesUpload(files);
    };

    const handleGalleryImageDrop = (event) => {
        event.preventDefault();
        setIsImageDragging(false);
        const files = event.dataTransfer.files;

        if (files.length > 1) {
            setErrorMessages(['Please select only one image.']);
            return;
        }

        handleFilesUpload(files);
    };

    const handleFilesUpload = (files) => {
        setLoadingFiles(true);
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const maxSize = 128 * 1024 * 1024; // 128MB

        let errors = [];

        if (files.length > 1) {
            errors.push('Please select only one image.');
        }

        for (let file of files) {
            if (!allowedTypes.includes(file.type)) {
                errors.push(`${file.name} is not a valid file type.`);
            }
            if (file.size > maxSize) {
                errors.push(`${file.name} exceeds the size limit of 128MB.`);
            }
        }

        if (errors.length > 0) {
            setErrorMessages(errors);
            setLoadingFiles(false);
        } else {
            const file = files[0]; // Uploading only one file

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result;
                // Update node's attribute with the base64 string
                editor.chain().focus().setImageBlock({ imageUrl: base64String, caption: '',imagelink:"", target: false }).run();

                setLoadingFiles(false);
            };
            reader.readAsDataURL(file);
        }
    };



    return (
        <NodeViewWrapper>
            <div data-type="image" className={`image-node-wrapper`} data-drag-handle>
                <div>
                    <div className={`newpageLeftRow textBlock uploadImageBlock ${loadingFiles ? 'uploadRunning' : ''}`}>
                        <div
                            className={isImageDragging ? 'dragging fileUploadBox' : 'fileUploadBox'}
                            onDragOver={handleFileDragOver}
                            onDragLeave={handleFileDragLeave}
                            onDrop={handleGalleryImageDrop}
                        >
                            <input
                                type="file"
                                ref={pulicationFileInputRef}
                                id="pulication-file"
                                onChange={handleImageGalleryUpload}
                                accept="image/*"
                                hidden
                            />
                            {
                                loadingFiles ?
                                    <div className="progress-wrap">
                                        <div className="progress">
                                            <div className="color"></div>
                                        </div>
                                    </div>
                                    :
                                    <div className="uploadFileBlock" onClick={() => document.getElementById("pulication-file").click()}>
                                        <div className="featureImgMiddleContent">
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='fileUploadCover_Img'>
                                                <g clip-path="url(#clip0_4592_9769)">
                                                    <path d="M14.175 17.375H6.06495C5.53486 17.375 5.26982 17.375 5.14709 17.2702C5.04059 17.1792 4.98408 17.0428 4.99507 16.9032C5.00773 16.7423 5.19515 16.5549 5.56998 16.18L13.0101 8.73995C13.3566 8.39344 13.5298 8.22018 13.7296 8.15526C13.9053 8.09816 14.0947 8.09816 14.2704 8.15526C14.4702 8.22018 14.6434 8.39343 14.9899 8.73995L18.375 12.125V13.175M14.175 17.375C15.6451 17.375 16.3802 17.375 16.9417 17.0889C17.4357 16.8372 17.8372 16.4357 18.0889 15.9417C18.375 15.3802 18.375 14.6451 18.375 13.175M14.175 17.375H6.825C5.35486 17.375 4.61979 17.375 4.05827 17.0889C3.56435 16.8372 3.16278 16.4356 2.91111 15.9417C2.625 15.3802 2.625 14.6451 2.625 13.175V5.825C2.625 4.35486 2.625 3.61979 2.91111 3.05827C3.16278 2.56435 3.56435 2.16278 4.05827 1.91111C4.61979 1.625 5.35486 1.625 6.825 1.625H14.175C15.6451 1.625 16.3802 1.625 16.9417 1.91111C17.4356 2.16278 17.8372 2.56435 18.0889 3.05827C18.375 3.61979 18.375 4.35486 18.375 5.825V13.175M9.1875 6.4375C9.1875 7.404 8.404 8.1875 7.4375 8.1875C6.471 8.1875 5.6875 7.404 5.6875 6.4375C5.6875 5.471 6.471 4.6875 7.4375 4.6875C8.404 4.6875 9.1875 5.471 9.1875 6.4375Z" stroke="#475467" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_4592_9769">
                                                        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p className="newPageImageUploadText">
                                                <span>Click to upload</span> or drag and drop <br />
                                                JPG or PNG
                                                (max. 128MB)
                                            </p>
                                            {errorMessages && errorMessages.length > 0 && (
                                                <div className="errorMessages">
                                                    {errorMessages.map((errorMessage, index) => (
                                                        <p key={index} className="error-message">
                                                            {errorMessage}
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>

                </div>
            </div>
        </NodeViewWrapper>
    );
};

export const ImageEmbeds = Node.create({
    name: 'image',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    addAttributes() {
        return {
            imageUrl: {
                default: '',
            },
            fullDisplay: {
                default: false,
            },
            imagelink:{
                default:''
            },
            target: {
                default: false,
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', { 'data-type': this.name, ...HTMLAttributes }];
    },

    addCommands() {
        return {
            setImageUpload: (imageUrl) => ({ commands }) => {
                return commands.insertContent(`<div data-type="${this.name}" data-image-url="${imageUrl}"></div>`);
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(ImageNodeView);
    },
});

