import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useEffect, useState } from 'react';

const ImageBlockView = (props) => {
    const { editor, getPos, node } = props;
    const isFullDisplay = node.attrs.layout || "default";
    const target = node.attrs.target || false
    
    return (
        <NodeViewWrapper data-type='image'>
            <div className='image-block' >
                {node.attrs.imageUrl ? (
                    <div className='image-preview'
                        style={{
                            width: isFullDisplay === "full" ? '70vw' : '100%',
                            position: isFullDisplay === "full" ? 'relative' : '',
                            left: isFullDisplay === "full" ? '50%' : '0',
                            transform: isFullDisplay === "full" ? 'translateX(-50%)' : 'translateX(0)',
                        }}>
                        {
                            node.attrs.imagelink !== "" && node.attrs.imagelink !== undefined ?
                                <a href={node.attrs.imagelink} target={target ? '_blank' : ""} style={{pointerEvents : "none"}}>
                                    <img src={node.attrs.imageUrl} alt="Uploaded" />
                                </a> :
                                <img src={node.attrs.imageUrl} alt="Uploaded" />
                        }
                        <div className='video-title' contentEditable={false}>
                            <p className={`video-caption`} contentEditable={true} >{node.attrs.caption}</p>
                        </div>
                    </div>
                ) : (
                    <p>No image available</p>
                )}
            </div>
        </NodeViewWrapper>
    );
};

export const ImageBlock = Node.create({
    name: 'imageBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            imageUrl: {
                default: '',
            },
            caption: {
                default: '',
            },
            width: {
                default: 'auto', // Default width value
            },
            layout: {
                default: "default"
            },
            imagelink: {
                default: ""
            },
            target: {
                default: false
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            { 'data-type': this.name, style: `width: ${HTMLAttributes.width}` }, // Apply the width as inline style
            [
                HTMLAttributes.imageUrl
                    ? ['img', { src: HTMLAttributes.imageUrl, alt: 'Uploaded', style: `width: ${HTMLAttributes.width}; height: auto;` }]
                    : '',
            ],
        ];
    },

    addCommands() {
        return {
            setImageBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                imageUrl: options?.imageUrl || '',
                                caption: options?.caption || '',
                                width: options?.width || 'auto', // Include width option
                                layout: options?.layout || "default",
                                imagelink: options?.imagelink || '',
                                target: options?.target || false
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(ImageBlockView);
    },
});


