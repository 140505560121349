import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react'

const OtherLinkBlockView = (props) => {
    const { editor, getPos, node } = props

    return (
        <NodeViewWrapper>
            <div contentEditable={false} className='link-content'>
                <div className="textBlock linkBox">
                    <div className='linkImageSection'>
                        <img className="previewImage" src={node.attrs.imageUrl} alt={node.attrs.title} />
                    </div>
                    <div className="linkData">
                        <div>
                            <p className="linkDataTitle">{node.attrs.title}</p>
                            <span className="linkDataDiscripation">{node.attrs.description}</span>
                        </div>
                    </div>
                 </div>
            </div>
        </NodeViewWrapper>
    )
}
export const OtherLinkBlock = Node.create({
    name: 'otherLinkBlock',
  
    group: 'block',

    defining: true,

    isolating: true,
  
    addAttributes() {
      return {
        title: {
          default: '',
        },
        description: {
          default: '',
        },
        imageUrl: {
          default: '',
        },
      };
    },
  
    parseHTML() {
      return [
        {
          tag: `div[data-type="${this.name}"]`,
        },
      ];
    },
  
    renderHTML({ HTMLAttributes }) {
      return [
        'div',
        { 'data-type': this.name },
        [
          'div',
          { class: 'bookmark-content' },
          [
            'h3',
            { class: 'bookmark-title' },
            HTMLAttributes.title || 'No Title',
          ],
          [
            'p',
            { class: 'bookmark-description' },
            HTMLAttributes.description || 'No Description',
          ],
          HTMLAttributes.imageUrl
            ? ['img', { src: HTMLAttributes.imageUrl, class: 'bookmark-image' }]
            : '',
        ],
      ];
    },
  
    addCommands() {
      return {
        setOtherLinkBlock:
          (options) =>
          ({ commands }) => {
            return commands.insertContent({
              type: this.name,
              attrs: {
                title: options?.title || 'No Title',
                description: options?.description || 'No Description',
                imageUrl: options?.imageUrl || '',
              },
            });
          },
      };
    },
  
    addNodeView() {
      return ReactNodeViewRenderer(OtherLinkBlockView);
    },
  });
  