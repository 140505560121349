import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState, useRef } from 'react';

const FileNodeView = ({ node, editor, getPos }) => {
    const [isFileDragging, setIsFileDragging] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const fileInputRef = useRef(null);

    const handleFileDragOver = (event) => {
        event.preventDefault();
        setIsFileDragging(true);
    };

    const handleFileDragLeave = () => {
        setIsFileDragging(false);
    };

    const handleFileUpload = (event) => {
        const files = event.target.files;
        handleFilesUpload(files);
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        setIsFileDragging(false);
        const files = event.dataTransfer.files;

        handleFilesUpload(files);
    };

    const handleFilesUpload = (files) => {
        let errors = [];
        if (files.length > 1) {
            errors.push('Please select only one image.');
            setErrorMessages(errors);
        } else {
            setLoadingFiles(true);
            const allowedTypes = [
                'application/pdf',
                'application/doc',
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.ms-powerpoint.presentation.macroenabled.12',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel.sheet.macroenabled.12',
            ];
            const maxSize = 128 * 1024 * 1024; 
            for (let file of files) {
                if (!allowedTypes.includes(file.type)) {
                    errors.push(`${file.name} is not a valid file type.`);
                }
                if (file.size > maxSize) {
                    errors.push(`${file.name} exceeds the size limit of 128MB.`);
                }
            }
    
            if (errors.length > 0) {
                setErrorMessages(errors);
                setLoadingFiles(false);
            } else {
                const file = files[0]; 
    
                const formatFileSize = (size) => {
                    if (size >= 1024 * 1024) {
                        return (size / (1024 * 1024)).toFixed(2) + ' MB'; 
                    } else if (size >= 1024) {
                        return (size / 1024).toFixed(2) + ' KB'; 
                    }
                    return size + ' Bytes'; 
                };
    
                const formattedFileSize = formatFileSize(file.size); 
    
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result;
    
                    editor.chain().focus().setFileBlock({
                        fileUrl: base64String,
                        fileName: file.name,
                        fileSize: formattedFileSize, 
                        caption: ''
                    }).run();
    
                    setLoadingFiles(false);
                };
                reader.readAsDataURL(file);
            }
        }
    };
    



    return (
        <NodeViewWrapper>
            <div data-type="file" className={`image-node-wrapper`} data-drag-handle>
                <div>
                    <div className={`newpageLeftRow textBlock uploadImageBlock ${loadingFiles ? 'uploadRunning' : ''}`}>
                        <div
                            className={isFileDragging ? 'dragging fileUploadBox' : 'fileUploadBox'}
                            onDragOver={handleFileDragOver}
                            onDragLeave={handleFileDragLeave}
                            onDrop={handleFileDrop}
                        >
                            <input
                                type="file"
                                ref={fileInputRef}
                                id="file-input"
                                onChange={handleFileUpload}
                                accept=".pdf, .doc, .docx, .ppt, .pptx, .ppsx, .xls, .xlsx"
                                hidden
                            />
                            {loadingFiles ? (
                                <div className="progress-wrap">
                                    <div className="progress">
                                        <div className="color"></div>
                                    </div>
                                </div>
                            ) : (

                                <div className="uploadFileBlock" onClick={() => document.getElementById("file-input").click()}>
                                    <div className="featureImgMiddleContent">
                                        <svg width="21" height="20" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" className='fileUploadCover_Img'>
                                            <path d="M11 1.70215V4.80005C11 5.22009 11 5.43011 11.0817 5.59055C11.1537 5.73167 11.2684 5.8464 11.4095 5.91831C11.5699 6.00005 11.78 6.00005 12.2 6.00005H15.2979M12.5 9.75H6.5M12.5 12.75H6.5M8 6.75H6.5M11 1.5H7.1C5.83988 1.5 5.20982 1.5 4.72852 1.74524C4.30516 1.96095 3.96095 2.30516 3.74524 2.72852C3.5 3.20982 3.5 3.83988 3.5 5.1V12.9C3.5 14.1601 3.5 14.7902 3.74524 15.2715C3.96095 15.6948 4.30516 16.039 4.72852 16.2548C5.20982 16.5 5.83988 16.5 7.1 16.5H11.9C13.1601 16.5 13.7902 16.5 14.2715 16.2548C14.6948 16.039 15.039 15.6948 15.2548 15.2715C15.5 14.7902 15.5 14.1601 15.5 12.9V6L11 1.5Z" stroke="#475467" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>

                                        <p className="newPageImageUploadText fileupload">
                                            <span>Click to upload</span> or drag and drop <br />
                                            pdf, doc, docx, ppt, pptx, pptm, xls, xlsx, xlsm (max. 128MB)
                                        </p>
                                        {errorMessages && errorMessages.length > 0 && (
                                            <div className="errorMessages">
                                                {errorMessages.map((errorMessage, index) => (
                                                    <p key={index} className="error-message">
                                                        {errorMessage}
                                                    </p>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </NodeViewWrapper>
    );
};


export const File = Node.create({
    name: 'file',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,
    selectable: true,
    inline: false,

    addAttributes() {
        return {
            fileUrl: {
                default: '',
            },
            fullDisplay: {
                default: false,
            },
            fileName: {
                default: ""
            },
            fileSize: {
                default: ""
            }

        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', { 'data-type': this.name, ...HTMLAttributes }];
    },

    addCommands() {
        return {
            setFileUpload: (fileUrl) => ({ commands }) => {
                return commands.insertContent(`<div data-type="${this.name}" data-image-url="${fileUrl}"></div>`);
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(FileNodeView);
    },
});

