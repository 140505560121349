import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import ReactPlayer from 'react-player';
import { useState } from 'react';

const VideoBlockView = (props) => {
    const { editor, getPos, node } = props;
    const isFullDisplay = node.attrs.layout || "default";
    const isLoopActive = node.attrs.loop || false;
    const [isVideoReady, setIsVideoReady] = useState(false);

    const handleVideoReady = () => {
        setIsVideoReady(true);
    };

    return (
        <NodeViewWrapper data-type='video'>
            <div className='video-wrapper' >
                {node.attrs.videoUrl ? (
                    <div className='image-preview' 
                    style={{
                        width: isFullDisplay === "wide" ? '70vw' : isFullDisplay === "fullwidth"  ? '99vw' : '100%',
                        position: (isFullDisplay === "wide" || isFullDisplay === "fullwidth") ? 'relative' : 'relative',
                        left: isFullDisplay === "wide" ? '50%' : isFullDisplay === "fullwidth" ? '50%' : '0',
                        transform: isFullDisplay === "wide" ? 'translateX(-50%)' : isFullDisplay === "fullwidth" ? 'translateX(-50%)' : 'translateX(0%)',
                    }}>
                        { !isVideoReady &&
                            <div contentEditable={false} className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                        }
                        <div contentEditable={false} className={(isVideoReady) ? 'player-wrapper player-ready' : 'player-wrapper'}>
                            <ReactPlayer
                                className='react-player'
                                url={node.attrs.videoUrl}
                                width='100%'
                                height='100%'
                                light={node.attrs.thumbnailurl}
                                controls
                                onReady={handleVideoReady}
                                loop={isLoopActive}
                            />
                        </div>
                        <div className='video-title' contentEditable={false}>
                            <p className='video-caption' contentEditable={true}>{node.attrs.caption}</p>
                        </div>
                    </div>
                ) : (
                    <p>No video available</p>
                )}
            </div>
        </NodeViewWrapper>
    );
};

export const VideoBlock = Node.create({
    name: 'VideoBlock',

    group: 'block',
    defining: true,
    isolating: true,

    addAttributes() {
        return {
            videoUrl: {
                default: '',
            },
            caption: {
                default: '',
            },
            width: {
                default: 'auto', // Default width value
            },
            layout:{
                default:"default"
            },
            thumbnailurl: {
                default:''
            },
            loop:{
                default: false
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            { 'data-type': this.name, style: `width: ${HTMLAttributes.width}` },
            [
                HTMLAttributes.videoUrl
                    ? ['img', { src: HTMLAttributes.videoUrl, alt: 'Uploaded', style: `width: ${HTMLAttributes.width}; height: auto;` }]
                    : '',
            ],
        ];
    },

    addCommands() {
        return {
            setVideoBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                videoUrl: options?.videoUrl || '',
                                caption: options?.caption || '',
                                width: options?.width || 'auto', 
                                layout: options?.layout || "default",
                                loop: options?.loop || false
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(VideoBlockView);
    },
});


