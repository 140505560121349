import React, { useState, useEffect, useRef, useCallback} from 'react'

import { EditorContent, useEditor, BubbleMenu } from '@tiptap/react'
import TextAlign from '@tiptap/extension-text-align'
import StarterKit from '@tiptap/starter-kit'
import FontFamily from '@tiptap/extension-font-family'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Highlight from '@tiptap/extension-highlight'
import Placeholder from '@tiptap/extension-placeholder'
import { Color } from '@tiptap/extension-color'
import { CodeBlockLowlight } from '@tiptap/extension-code-block-lowlight'
import { getHierarchicalIndexes, TableOfContents } from '@tiptap-pro/extension-table-of-contents'
import { all, createLowlight } from 'lowlight'


import { ContentItemMenu } from './ContentItemMenu'
import FontSize from './extensions/FontSize'
import Table from './extensions/Table/Table'
import TableHeader from './extensions/Table/Header'
import TableCell from './extensions/Table/Cell'
import TableRow from './extensions/Table/Row'
import SlashCommand from './SlashCommand/SlashCommand'
import { LinkMenu } from './LinkMenu'

import '../App.css';
import TextMenu from './TextMenu/TextMenu'
import TableRowMenu from './extensions/Table/menus/TableRow'
import TableColumnMenu from './extensions/Table/menus/TableColumn'
import Columns from './extensions/MultiColumn/Columns'
import Column from './extensions/MultiColumn/Column'
import HorizontalRule from './extensions/HorizontalRule'
import Document from './extensions/Document'
import { TrailingNode } from './extensions/TrailingNode'
import { TableOfContentsNode } from './extensions/TableOfContentsNode/TableOfContentsNode'
import Selection from './extensions/Selection'
import Link from './extensions/Link'
import Heading from './extensions/Heading'
import BlockquoteFigure from './extensions/BlockquoteFigure/BlockquoteFigure'
import Quote from './extensions/BlockquoteFigure/Quote'
import QuoteCaption from './extensions/BlockquoteFigure/QuoteCaption'
import { Button } from './extensions/Button/Button'
import ButtonBubbleMenu from './extensions/Button/ButtonBubbleMenu'
import { Bookmark } from './extensions/Bookmark/Bookmark'
import { BookmarkBlock } from './extensions/Bookmark/BookmarkBlock'
import { YouTubeEmbeds } from './extensions/Embeds/YouTube/YouTubeEmbeds'
import { YouTubeBlock } from './extensions/Embeds/YouTube/YouTubeBlock'
import { VimeoEmbeds } from './extensions/Embeds/Vimeo/VimeoEmbeds'
import { VimeoBlock } from './extensions/Embeds/Vimeo/VimeoBlock'
import { TwitterEmbeds } from './extensions/Embeds/Twitter/TwitterEmbeds'
import { SpotifyEmbeds } from './extensions/Embeds/Spotify/SpotifyEmbeds'
import { SoundCloudEmbeds } from './extensions/Embeds/SoundCloud/SoundCloudEmbeds'
																  
import { OtherEmbeds } from './extensions/Embeds/Other/OtherEmbeds'
import { OtherBlock } from './extensions/Embeds/Other/OtherBlock'
import { OtherLinkBlock } from './extensions/Embeds/Other/OtherLinkBlock'
import { Publiations } from './extensions/Publiations/Publiations'
import { Product } from './extensions/Product/Product'
import { ImageBlock } from './extensions/Image/ImageBlock'
import ImageBubbleMenu from './extensions/Image/ImageBubbleMenu'
import { ImageEmbeds } from './extensions/Image/Image'
import { ImageLinkMenu } from './extensions/Image/ImageLink'
import { AiWriter } from './extensions/AiWriter/AiWriter'
import ColumnBubbleMenu from './extensions/MultiColumn/ColumnBubbleMenu'
import { GalleryView } from './extensions/GalleryImage/GalleryView'
import { GalleryBlock } from './extensions/GalleryImage/GalleryBlock'
import PublicationBubbleMenu from './extensions/Publiations/PublicationBubbleMenu'
import { VideoEmbeds } from './extensions/Video/Video'
import { VideoBlock } from './extensions/Video/VideoBlock'
import VideoBubbleMenu from './extensions/Video/VideoBubbleMenu'
import GalleryBubbleMenu from './extensions/GalleryImage/GalleryBubbleMenu'
import ProductBubbleMenu from './extensions/Product/ProductBubbleMenu'
import { File } from './extensions/File/File'
import { FileBlock } from './extensions/File/FileBlock'
import FileBubbleMenu from './extensions/File/FileBubbleMenu'
import History from '@tiptap/extension-history'
import { LogoSlider } from './extensions/LogoSlider/LogoSlider'
import { LogoSliderBlock } from './extensions/LogoSlider/LogoSliderBlock'
import LogoSliderBubbleMenu from './extensions/LogoSlider/LogoSliderBubbleMenu'
import { UrlPaste } from './extensions/URLpaste/UrlPaste'
import UrlPasteBlock from './extensions/URLpaste/UrlPasteBlock'
import { SpotifyBlock } from './extensions/Embeds/Spotify/SpotifyBlock'
import { TwitterBlock } from './extensions/Embeds/Twitter/TwitterBlock'
import { SoundCloudBlock } from './extensions/Embeds/SoundCloud/SoundCloudBlock'

const lowlight = createLowlight(all)

export const EditorComponent = () => {
    const mainContainerRef = useRef(null)

    const editor = useEditor({
        extensions: [
            Document,
            Selection,
            Heading.configure({
              levels: [1, 2, 3, 4, 5, 6],
            }),
            TextStyle, 
            Underline,
            Subscript,
            Superscript,
            TaskList,
            TaskItem.configure({
              nested: true,
            }),
            TrailingNode,
            Table,
            TableCell,
            TableHeader,
            TableRow,
            StarterKit.configure({
                document: false,
                dropcursor: false,
                heading: false,
                horizontalRule: false,
                blockquote: false,
                history: true,
                codeBlock: false,
            }),
            Placeholder.configure({
                includeChildren: true,
                showOnlyCurrent: false,
                placeholder: () => '',
            }),
            FontSize,
            Color,
            Quote,
            QuoteCaption,
            BlockquoteFigure,
            Columns,
            Column,
            HorizontalRule,
            SlashCommand,
            TableOfContents.configure({
                getIndex: getHierarchicalIndexes,
            }),
            TableOfContentsNode,
            Highlight.configure({ multicolor: true }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Link.configure({
              openOnClick: false,
              autolink: true,
              defaultProtocol: 'https',
            }),
            CodeBlockLowlight.configure({
              lowlight,
              defaultLanguage: 'javascript',
            }),  
            Button,
            BookmarkBlock,
            Bookmark,
            Publiations,
            Product,
            YouTubeBlock,
            YouTubeEmbeds,
            VimeoBlock,
            VimeoEmbeds,
            TwitterEmbeds,
            TwitterBlock,
            SpotifyEmbeds,
            SpotifyBlock,
            SoundCloudEmbeds,
            SoundCloudBlock,
            OtherEmbeds,
            OtherBlock,
            OtherLinkBlock,
            ImageBlock,
            ImageEmbeds,
            AiWriter,
            GalleryView,
            GalleryBlock,
            VideoEmbeds,
            VideoBlock,
            FileBlock,
            File,
            LogoSlider,
            LogoSliderBlock,
            UrlPaste,
            UrlPasteBlock,
        ],
        editorProps: {
            handlePaste: function(view, event) {

                const clipboardData = event.clipboardData;
                const text = clipboardData.getData('text');
                console.log({text:text})
                // Simple URL validation
                const urlRegex = /(https?:\/\/[^\s]+)/g;
                const iframeRegex = /<iframe.*?src="(.*?)".*?<\/iframe>/g;
                if(iframeRegex.test(text)){
                    const { state } = view;
                    const { $from, empty } = state.selection;
                    const currentNode = $from.node();
                    const isCurrentNodeEmpty = currentNode.isTextblock && currentNode.content.size === 0;

                    if (empty && isCurrentNodeEmpty) {
                        event.preventDefault();
                        view.dispatch(
                            view.state.tr.replaceSelectionWith(
                              view.state.schema.nodes.urlPaste.create({url:text})
                            )
                        );
                        return true;
                    }
                } else {
                    if (urlRegex.test(text)) {
                        const { state } = view;
                        const { $from, empty } = state.selection;
                        const currentNode = $from.node();
                        const isCurrentNodeEmpty = currentNode.isTextblock && currentNode.content.size === 0;
    
                        if (empty && isCurrentNodeEmpty) {
                            event.preventDefault();
    
                            // Replace the URL with your custom element if the current node is empty
                            const url = text.match(urlRegex)[0];
    
                            view.dispatch(
                                view.state.tr.replaceSelectionWith(
                                  view.state.schema.nodes.urlPaste.create({url:url})
                                )
                            );
                            return true;
                        }
                    }
                }
                

                return false;
            }
        },
        content: `
      <p>
        Hey, try to select some text here. There will popup a menu for selecting some inline styles. Remember: you have full control about content and styling of this menu.
      </p>
    `,
    })

    const [isOpen, setOpen] = useState(false)
    const [moreOption, setMoreOption] = useState(false);
    const menuRef = useRef();
    const dropdownRef = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isOpen && setOpen && menuRef?.current && !menuRef?.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [isOpen]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (moreOption && setMoreOption && dropdownRef?.current && !dropdownRef?.current.contains(e.target)) {
                setMoreOption(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [moreOption]);
    
    return (
        <>
        <div className='tiptop-editor' ref={mainContainerRef}>
            <EditorContent editor={editor} /> 
            <ContentItemMenu editor={editor} />
            <TextMenu editor={editor} />
            <LinkMenu editor={editor} appendTo={mainContainerRef} />
            <TableRowMenu editor={editor} appendTo={mainContainerRef} />
            <TableColumnMenu editor={editor} appendTo={mainContainerRef} />
            <ButtonBubbleMenu editor={editor} appendTo={mainContainerRef} />
            <ImageBubbleMenu editor={editor} appendTo={mainContainerRef} /> 
            <ColumnBubbleMenu  editor={editor} appendTo={mainContainerRef} />
            <PublicationBubbleMenu editor={editor} appendTo={mainContainerRef}/>
            <VideoBubbleMenu editor={editor} appendTo={mainContainerRef} />
            <GalleryBubbleMenu editor={editor} appendTo={mainContainerRef}/>
            <ProductBubbleMenu editor={editor} appendTo={mainContainerRef}/>
            <FileBubbleMenu editor={editor} appendTo={mainContainerRef}/>
            <LogoSliderBubbleMenu editor={editor} appendTo={mainContainerRef}/>
        </div>
        </>
    )
}
