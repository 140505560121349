import { Node } from '@tiptap/core';

export const ColumnLayout = {
  SidebarLeft: 'sidebar-left',
  SidebarRight: 'sidebar-right',
  TwoColumn: 'two-column',
};

const Columns = Node.create({
  name: 'columns',
  group: 'block',
  content: 'column column', // Require exactly two column nodes
  defining: true,
  isolating: true,

  addAttributes() {
    return {
      layout: {
        default: ColumnLayout.TwoColumn,
      },
    };
  },

  addCommands() {
    return {
      setColumns: () => ({ commands }) => 
        commands.insertContent({
          type: 'columns',
          content: [
            { type: 'column', attrs: { position: 'left' }, content: [{ type: 'paragraph'}] },
            { type: 'column', attrs: { position: 'right' }, content: [{ type: 'paragraph' }] }
          ]
        }),
      setLayout: (layout) => ({ commands }) => 
        commands.updateAttributes('columns', { layout }),
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', { 'data-type': 'columns', class: `layout-${HTMLAttributes.layout}` }, 0];
  },

  parseHTML() {
    return [{ tag: 'div[data-type="columns"]' }];
  },
});

export default Columns;
