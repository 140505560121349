
import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useEffect, useState } from 'react';
import {   A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle'
const LogoSliderNodeView = (props) => {
    const { editor, getPos, node } = props;
    const isFullDisplay = node.attrs.layout || "default";
    const images = node.attrs.imageUrls || false
    return (
        <NodeViewWrapper data-type='logoSlider'>
            <div className='logoslider-wapper' style={{
                        width: isFullDisplay === "full" ? '70vw' : '100%',
                        position: isFullDisplay === "full" ? 'relative' : '',
                        left: isFullDisplay === "full" ? '50%' : '0',
                        transform: isFullDisplay === "full" ? 'translateX(-50%)' : 'translateX(0)',
                    }}>
                <Swiper
                    modules={[A11y]}
                    slidesPerView={isFullDisplay === "full" ? 5 : 3}
                    spaceBetween={20}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: isFullDisplay === "full" ? 5 : 3,
                            spaceBetween: 20,
                        },
                    }}
                >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </NodeViewWrapper>
    );
};
export const LogoSliderBlock = Node.create({
    name: 'logoSliderBlock',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
            imageUrls: {
                default: '',
            },
            caption: {
                default: '',
            },
            layout: {
                default: 'default',
            }
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            { 'data-type': this.name, style: `width: ${HTMLAttributes.width}` }, // Apply the width as inline style
            ...HTMLAttributes.imageUrls?.map((item) => (
                ['img', { src: item, alt: 'Uploaded', style: `width: ${HTMLAttributes.width}; height: auto;` }]
            )),
        ];
    },

    addCommands() {
        return {
            setLogoSliderBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                imageUrls: options?.imageUrls || '',
                                caption: options?.caption || '',
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(LogoSliderNodeView);
    },
});
