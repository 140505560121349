import { Node, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { useState } from 'react';
import ReactPlayer from 'react-player/lazy'

const VimeoBlockView = (props) => {
    const { editor, getPos, node } = props;


    console.log({node})

    const [isVideoReady, setIsVideoReady] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleVideoReady = () => {
        // Set loading to false once the video is ready to play
        setIsVideoReady(true);
    };
    const handleVideoError = (error) => {
        setIsError(true);
        setErrorMessage(error.message)
    };
    
    
    return(
        <NodeViewWrapper data-type='vimeo'>
            <div className='video-wrapper vimeo-video'>
                { (!isVideoReady && ! isError) &&
                    <div contentEditable={false} className="skeleton-video-wrapper"><div className="skeleton-video"></div></div>
                }
                <div contentEditable={false} className={(isVideoReady) ? 'player-wrapper player-ready' : 'player-wrapper'}>
                    <ReactPlayer
                        className='react-player'
                        url={node.attrs.videourl}
                        width='100%'
                        height='100%'
                        controls
                        onReady={handleVideoReady}
                        onError={handleVideoError}
                    />
                </div>
                {! isError &&
                  <div className='video-title' contentEditable={false}>
                      <p className={`video-caption`} contentEditable={true} >{node.attrs.caption}</p>
                  </div>
                }
                {isError &&
                  <p className='error-message'>{errorMessage}</p>
                }
            </div>
        </NodeViewWrapper>
    )
}

export const VimeoBlock = Node.create({
    name: 'vimeoBlock',
    
    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
          videourl: {
            default: '',
          },
          caption: {
            default: '',
          },
        };
    },

    parseHTML() {
        return [
          {
            tag: `div[data-type="${this.name}"]`,
          },
        ];
    },

    renderHTML({ HTMLAttributes }) {
      return [
          'div',
          { 'data-type': this.name },
          [
              'div',
              { class: 'vimeo-content' },
              [
                  'p',
                  { class: 'video-caption' },
                  HTMLAttributes.caption || '',
              ],
              HTMLAttributes.videourl
                  ? [
                        'iframe',
                        {
                            src: HTMLAttributes.videourl,
                            class: 'react-player',
                            allow: 'autoplay; fullscreen',
                            frameborder: '0',
                            allowfullscreen: true,
                        },
                    ]
                  : '',
          ],
      ];
    },

    addCommands() {
        return {
            setVimeoBlock:
            (options) =>
            ({ commands }) => {
              return commands.insertContent({
                type: this.name,
                attrs: {
                    videourl: options?.videourl || '',
                    caption: options?.caption || '',
                },
              });
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(VimeoBlockView);
    },
});