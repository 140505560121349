export const GROUPS = [
  {
    name: 'ai',
    title: 'AI',
    commands: [
      {
        name: 'aiWriter',
        label: 'AI Writer',
        iconName: 'AI',
        description: 'High priority section title',
        aliases: ['h1'],
        allowInColumn: false,
        action: (editor) => editor.chain().focus().setAiWriter().run(),
      },
    ]
  },
  {
    name: 'format',
    title: 'Format',
    commands: [
      {
        name: 'heading1',
        label: 'Heading 1',
        iconName: 'Heading1',
        description: 'High priority section title',
        aliases: ['h1'],
        allowInColumn: true,
        action: (editor) => editor.chain().focus().setHeading({ level: 1 }).run(),
      },
      {
        name: 'heading2',
        label: 'Heading 2',
        iconName: 'Heading2',
        description: 'Medium priority section title',
        aliases: ['h2'],
        action: (editor) => editor.chain().focus().setHeading({ level: 2 }).run(),
      },
      {
        name: 'heading3',
        label: 'Heading 3',
        iconName: 'Heading3',
        description: 'Low priority section title',
        aliases: ['h3'],
        allowInColumn: true,
        action: (editor) => editor.chain().focus().setHeading({ level: 3 }).run(),
      },
      {
        name: 'button',
        label: 'Button',
        iconName: 'Button',
        description: 'Low priority section title',
        aliases: ['button'],
        allowInColumn: true,
        shouldBeHidden: (editor) => editor.isActive('button'),
        action: (editor) => editor.commands.setButton(),
      },
      {
        name: 'bookmark',
        label: 'Bookmark',
        iconName: 'Bookmark',
        description: 'Insert a bookmark from a URL',
        aliases: ['bookmark'],
        allowInColumn: false,
        action: (editor) => editor.commands.setBookmark(),
      },      
      {
        name: 'bulletList',
        label: 'Bullet List',
        iconName: 'List',
        description: 'Unordered list of items',
        aliases: ['ul'],
        allowInColumn: true,
        action: (editor) => editor.chain().focus().toggleBulletList().run(),
      },
      {
        name: 'numberedList',
        label: 'Numbered List',
        iconName: 'ListOrdered',
        description: 'Ordered list of items',
        aliases: ['ol'],
        allowInColumn: true,
        action: (editor) => editor.chain().focus().toggleOrderedList().run(),
      },
      {
        name: 'taskList',
        label: 'Task List',
        iconName: 'ListTodo',
        description: 'Task list with todo items',
        aliases: ['todo'],
        allowInColumn: true,
        action: (editor) => editor.chain().focus().toggleTaskList().run(),
      },
      {
        name: 'blockquote',
        label: 'Blockquote',
        iconName: 'Quote',
        description: 'Element for quoting',
        aliases: ['blockquote', 'quote'],
        allowInColumn: true,
        action: (editor) => editor.chain().focus().setBlockquote().run(),
      },
      {
        name: 'codeBlock',
        label: 'Code Block',
        iconName: 'SquareCode',
        description: 'Code block with syntax highlighting',
        allowInColumn: false,
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => editor.chain().focus().setCodeBlock().run(),
      },
    ],
  },
  {
    name: 'insert',
    title: 'Insert',
    commands: [
      {
        name: 'table',
        label: 'Table',
        iconName: 'Table',
        description: 'Insert a table',
        allowInColumn: false,
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run(),
      },
      {
        name: 'image',
        label: 'Image',
        iconName: 'Image',
        description: 'Insert an image',
        aliases: ['img'],
        allowInColumn: true,
        action: (editor) => editor.chain().focus().setImageUpload().run(),
      },
      {
        name: 'gallery',
        label: 'Gallery',
        iconName: 'Gallery',
        description: 'Insert a gallery',
        aliases: ['gallery', 'images'],
        allowInColumn: false,
        action: (editor) => editor.chain().focus().setGalleryUpload().run(),
      },
      {
        name: 'video',
        label: 'Video',
        iconName: 'Video',
        description: 'Insert a Video',
        aliases: ['video'],
        shouldBeHidden: (editor) => editor.isActive('video'),
        action: (editor) => editor.chain().focus().setVideoUpload().run(),
      },
      {
        name: 'file',
        label: 'File',
        iconName: 'File',
        description: 'Insert a File',
        aliases: ['file'],
        action: (editor) => editor.chain().focus().setFileUpload().run(),
      },
      {
        name: 'columns',
        label: 'Columns',
        iconName: 'Columns',
        description: 'Add two column content',
        aliases: ['cols'],
        allowInColumn: false,
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => editor.chain().focus().setColumns().focus(editor.state.selection.head - 1).run(),
      },
      {
        name: 'horizontalRule',
        label: 'Divider',
        iconName: 'Minus',  
        description: 'Insert a horizontal divider',
        aliases: ['hr'],
        allowInColumn: true,
        action: (editor) => editor.chain().focus().setHorizontalRule().run(),
      },
      {
        name: 'toc',
        label: 'Table of Contents',
        iconName: 'Book',
        aliases: ['outline'],
        description: 'Insert a table of contents',
        allowInColumn: true,
        shouldBeHidden: (editor) => editor.isActive('columns'),
        action: (editor) => editor.chain().focus().insertTableOfContents().run(),
      },
      {
        name: 'publications',
        label: 'Publications',
        iconName: 'Publications',
        aliases: ['publication'],
        description: 'Insert a publication',
        allowInColumn: false,
        shouldBeHidden: (editor) => editor.isActive('publiations'),
        action: (editor) => editor.chain().focus().setPublications().run(),
      },
      {
        name: 'products',
        label: 'Product',
        iconName: 'Products',
        aliases: ['products'],
        description: 'Insert a product',
        allowInColumn: false,
        action: (editor) => editor.chain().focus().setProduct().run(),
      },
      {
        name: 'logs',
        label: 'Logo Slider',
        iconName: 'LogoSlider',
        aliases: ['logo', 'slider'],
        allowInColumn: false,
        description: 'Insert a logo slider',
        action: (editor) => editor.chain().focus().setLogoSlider().run(),
      },
    ],
  },
  {
    name: 'embeds',
    title: 'Embeds',
    commands: [
      {
        name: 'youtube',
        label: 'YouTube',
        iconName: 'YouTube',
        description: 'Insert a table',
        allowInColumn: true,
        action: (editor) => editor.commands.setYoutubeEmbeds(),
      },
      {
        name: 'x',
        label: 'X (Formerly Twitter)',
        iconName: 'Twitter',
        description: 'Insert Twitter Embeds',
        allowInColumn: true,
        action: (editor) => editor.commands.setTwitterEmbeds(),
      },
      {
        name: 'vimeo',
        label: 'Vimeo',
        iconName: 'Vimeo',
        description: 'Insert a table',
        allowInColumn: true,
        action: (editor) => editor.commands.setVimeoEmbeds(),
      },
      {
        name: 'spotify',
        label: 'Spotify',
        iconName: 'Spotify',
        description: 'Insert a table',
        allowInColumn: true,
        action: (editor) => editor.commands.setSpotifyEmbeds(),
      },
      {
        name: 'soundcloud',
        label: 'SoundCloud',
        iconName: 'SoundCloud',
        description: 'Insert a table',
        allowInColumn: true,
        action: (editor) => editor.commands.setSoundCloudEmbeds(),
      },
      {
        name: 'otherembeds',
        label: 'Iframe',
        iconName: 'Iframe',
        description: 'Insert a table',
        allowInColumn: true,
        action: (editor) => editor.commands.setOtherEmbeds(),
      },
    ],
  },
]

export default GROUPS
