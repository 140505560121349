import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { NodeViewWrapper } from '@tiptap/react';
import { useState, useEffect, useRef } from 'react';

const TwitterNodeView = ({ node }) => {
  const tweetRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const embedTweet = async () => {
      const tweetId = node.attrs.twitterUrl.split('/').pop(); // Extract tweet ID from the URL
      try {
        if (window.twttr && tweetId) {
          const result = await window.twttr.widgets.createTweetEmbed(tweetId, tweetRef.current);
          if (!result) {
            setErrorMessage("Failed to load tweet. Please check the URL.");
          }
        }
      } catch (error) {
        console.error("Error embedding tweet:", error);
        setErrorMessage("An error occurred while loading the tweet.");
      }
    };

    embedTweet();
  }, []);

  return (
    <NodeViewWrapper>
      <div data-type="twitter" className="twitter-node-wrapper" data-drag-handle>
        {errorMessage ? (
          <p className="error-message">{errorMessage}</p>
        ) : (
          <div ref={tweetRef} className="twitter-embed"></div>
        )}
      </div>
    </NodeViewWrapper>
  );
};

export const TwitterBlock = Node.create({
    name: 'twitterBlock',

    isolating: true,
    defining: true,
    group: 'block',
    draggable: true,

    addAttributes() {
        return {
            twitterUrl: {
                default: '', // Set default empty string for the Twitter URL
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: `div[data-type="${this.name}"]`,
            },
        ];
    },

    renderHTML({ node }) {
        return ['div', { 'data-type': this.name }];
    },

    addCommands() {
        return {
            setTwitterBlock:
                (options) =>
                    ({ commands }) => {
                        return commands.insertContent({
                            type: this.name,
                            attrs: {
                                twitterUrl: options?.twitterUrl || '', // Set twitterUrl in node attributes
                            },
                        });
                    },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(TwitterNodeView); // Attach TwitterNodeView to render embedded tweet
    },
});
