import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { PubliationsBlockView } from './PubliationsBlockView';

export const Publiations = Node.create({
    name: 'publiations',
    
    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
          categories: {
            default: [],
          },
          layout: {
            default: 'grid',
          },
          count: {
            default: 3,
          },
          column: {
            default: 3,
          },
        };
    },

    parseHTML() {
        return [
          {
            tag: `div[data-type="${this.name}"]`,
          },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
          'div',
          { 'data-type': this.name },
          [
            'div',
            { class: 'paublication-container' },
          ],
        ];
    },

    addCommands() {
        return {
            setPublications:
                (options) =>
                ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: {
                        categories: options?.categories || [],
                        layout: options?.layout || 'grid',
                        count: options?.count || 3,
                        column: options?.column || 3,
                    },
                });
            },
            updatePublications:
                (options) =>
                ({ commands }) => {
                return commands.updateAttributes(this.name, options);
            },
            isActive: () => ({ commands }) => {
                return commands.isActive(this.name);
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(PubliationsBlockView);
    },


});