import { Node, ReactNodeViewRenderer } from '@tiptap/react';
import { ProductBlockView } from './ProductBlockView';

export const Product = Node.create({
    name: 'product',

    group: 'block',

    defining: true,

    isolating: true,

    addAttributes() {
        return {
          image: {
            default: '',
          },
          title: {
            default: '',
          },
          discription: {
            default: '',
          },          
          showRating: {
            default: false,
          },
          rating: {
            default: 5,
          },
          showButton: {
            default: false,
          },
          buttonText: {
            default: 'Buy Now',
          },
          buttonUrl: {
            default: '',
          },
        };
    },

    parseHTML() {
        return [
          {
            tag: `div[data-type="${this.name}"]`,
          },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return [
          'div',
          { 'data-type': this.name },
          [
            'div',
            { class: 'product-container' },
          ],
        ];
    },

    addCommands() {
        return {
            setProduct:
                (options) =>
                ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: {
                        image: options?.image || '',
                        title: options?.title || '',
                        discription: options?.discription || '',
                        showRating: options?.showRating || false,
                        rating: options?.rating || 5,
                        showButton: options?.showButton || false,
                        buttonText: options?.buttonText || '',
                        buttonUrl: options?.buttonUrl || '',
                    },
                });
            },
            updateProduct:
                (options) =>
                ({ commands }) => {
                return commands.updateAttributes(this.name, options);
            },
            isActive: () => ({ commands }) => {
                return commands.isActive(this.name);
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(ProductBlockView);
    },
});