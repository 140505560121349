import { mergeAttributes } from '@tiptap/core'
import TiptapHorizontalRule from '@tiptap/extension-horizontal-rule'

const HorizontalRule = TiptapHorizontalRule.extend({
  renderHTML() {
    return ['div', mergeAttributes(this.options.HTMLAttributes, { 'data-type': this.name }), ['hr']]
  },
})


/*
import React from 'react';
import { mergeAttributes } from '@tiptap/core';
import TiptapHorizontalRule from '@tiptap/extension-horizontal-rule';

const HorizontalRule = () => {
  const attributes = mergeAttributes(TiptapHorizontalRule.options.HTMLAttributes, {
    'data-type': TiptapHorizontalRule.name,
  });

  return (
    <div {...attributes}>
      <hr />
    </div>
  );
};
*/

export default HorizontalRule;
